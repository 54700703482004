<template>
  <div>
    <!-- self-assessment start  -->
    <section class="self_assessment_sec d-flex justify-content-center align-items-center">
      <div class="container px-lg-0">
        <div class="row">
          <div class="col-md-12">
            <div class="give_para mt_80 mb_120 text-center" style="padding:20px; height:800px">
              <!-- <h3 class="font_size_46 text_black">Which work setup do you want to evaluate today?</h3> -->
              
              <iframe :src="company_data.formlink_self_assessment+'?firstname='+this.user_data.first_name+'&lastname='+this.lastName+'&email='+this.user_data.email" frameborder="0" style="width: 100%;
    height: 649px;
    margin-top: 67px;"></iframe>
              <!-- <carousel :margin="16" :nav="true" :navText= "['&larr;','&rarr;']" :rewind="false" :items="2"
                :responsive="{0:{items:1, nav:true},768:{items:1,  nav:true},1280:{items:2, nav:true},1400:{items:2}}"
                class="owl-slider"> 
                <div class="item">
                  <div class="word-section-radio">
                    <svg xmlns="http://www.w3.org/2000/svg" width="104.077" height="103.678"
                      viewBox="0 0 104.077 103.678">
                      <g data-name="Group 12330">
                        <path data-name="Path 1" d="m334.695 172.387 66.736-17.968v72.034h-8.013v22.175h-58.723z"
                          transform="translate(-333.195 -152.919)"
                          style="stroke-linecap:round;stroke-linejoin:round;stroke:#ff9527;stroke-width:3px;fill:none" />
                        <g data-name="Group 12326">
                          <g data-name="Rectangle 77" style="stroke:#ff9527;stroke-width:3px;fill:none">
                            <path style="stroke:none" d="M0 0h24v17H0z" transform="translate(8.805 24.081)" />
                            <path style="fill:none" d="M1.5 1.5h21v14h-21z" transform="translate(8.805 24.081)" />
                          </g>
                          <path data-name="Line 1" transform="translate(21.305 26.581)"
                            style="stroke:#ff9527;stroke-width:3px;fill:none" d="M0 0v13" />
                        </g>
                        <g data-name="Group 12327">
                          <g data-name="Rectangle 77" style="stroke:#ff9527;stroke-width:3px;fill:none">
                            <path style="stroke:none" d="M0 0h24v17H0z" transform="translate(36.805 24.081)" />
                            <path style="fill:none" d="M1.5 1.5h21v14h-21z" transform="translate(36.805 24.081)" />
                          </g>
                          <path data-name="Line 1" transform="translate(49.305 26.581)"
                            style="stroke:#ff9527;stroke-width:3px;fill:none" d="M0 0v13" />
                        </g>
                        <g data-name="Group 12328">
                          <g data-name="Rectangle 77" style="stroke:#ff9527;stroke-width:3px;fill:none">
                            <path style="stroke:none" d="M0 0h24v17H0z" transform="translate(8.805 48.081)" />
                            <path style="fill:none" d="M1.5 1.5h21v14h-21z" transform="translate(8.805 48.081)" />
                          </g>
                          <path data-name="Line 1" transform="translate(21.305 50.581)"
                            style="stroke:#ff9527;stroke-width:3px;fill:none" d="M0 0v13" />
                        </g>
                        <g data-name="Group 12329">
                          <g data-name="Rectangle 77" style="stroke:#ff9527;stroke-width:3px;fill:none">
                            <path style="stroke:none" d="M0 0h24v17H0z" transform="translate(36.805 48.081)" />
                            <path style="fill:none" d="M1.5 1.5h21v14h-21z" transform="translate(36.805 48.081)" />
                          </g>
                          <path data-name="Line 1" transform="translate(49.305 50.581)"
                            style="stroke:#ff9527;stroke-width:3px;fill:none" d="M0 0v13" />
                        </g>
                        <path data-name="Path 2" d="M361.068 247.959v-21.71h14v21.71"
                          transform="translate(-333.195 -152.249)" style="stroke:#ff9527;stroke-width:3px;fill:none" />
                        <path data-name="Path 3" d="M400.318 226.918h34.7V255.1h-41.767v-7.2"
                          transform="translate(-332.446 -152.919)"
                          style="stroke-linecap:round;stroke-linejoin:round;stroke:#ff9527;stroke-width:3px;fill:none" />
                        <path data-name="Line 2" transform="translate(61.305 88.581)"
                          style="stroke:#ff9527;stroke-width:3px;fill:none" d="M0 0h41" />
                        <g data-name="Rectangle 78" transform="translate(76.805 83.081)"
                          style="fill:#fff;stroke:#ff9527;stroke-width:3px">
                          <rect width="10" height="10" rx="1" style="stroke:none" />
                          <rect x="1.5" y="1.5" width="7" height="7" rx=".5" style="fill:none" />
                        </g>
                        <path data-name="Path 4"
                          d="M407.9 225.62s0-6.247 4.3-7.125c1.015-.216 4.011-.689 6.294.539 1.849.987 3.361 3.134 3.431 6.586"
                          transform="translate(-333.195 -152.919)" style="stroke:#ff9527;stroke-width:3px;fill:none" />
                      </g>
                    </svg>
                    <p>My office set up</p>
                    <div class="custom_radio_main_box">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                        <label class="form-check-label" for="flexRadioDefault2">
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="word-section-radio">
                    <svg xmlns="http://www.w3.org/2000/svg" width="104.7" height="104.452" viewBox="0 0 104.7 104.452">
    <g data-name="Group 12335" transform="translate(-1159.11 -788.008)">
        <path data-name="Path 5" d="M936.841 220.14v35.82H905.61v-79.609l50.2-21.843 51.5 21.843v79.609h-8.025v-35.82z" transform="translate(255 635)" style="stroke-linecap:round;stroke-linejoin:round;stroke:#ff9527;stroke-width:3px;fill:none"/>
        <path data-name="Path 6" d="M905.61 213.889h101.8" transform="translate(255 635)" style="stroke:#ff9527;stroke-width:3px;fill:none"/>
        <g data-name="Rectangle 82" style="stroke:#ff9527;stroke-width:3px;fill:none">
            <path style="stroke:none" d="M0 0h30v26H0z" transform="translate(1167 816)"/>
            <path style="fill:none" d="M1.5 1.5h27v23h-27z" transform="translate(1167 816)"/>
        </g>
        <g data-name="Rectangle 83" style="fill:#fff;stroke:#ff9527;stroke-width:3px">
            <path style="stroke:none" d="M0 0h17v15H0z" transform="translate(1205 816)"/>
            <path style="fill:none" d="M1.5 1.5h14v12h-14z" transform="translate(1205 816)"/>
        </g>
        <path data-name="Path 7" d="M922.433 207v6.154" transform="translate(255 635)" style="stroke:#ff9527;stroke-width:3px;fill:none"/>
        <path data-name="Path 8" d="M922.433 207v6.154" transform="translate(264 635)" style="stroke:#ff9527;stroke-width:3px;fill:none"/>
        <circle data-name="Ellipse 2" cx="2.5" cy="2.5" r="2.5" transform="translate(1180 832)" style="fill:#ff9527"/>
        <path data-name="Rectangle 84" transform="translate(1208 812)" style="fill:#ff9527" d="M0 0h3v4H0z"/>
        <path data-name="Rectangle 85" transform="translate(1215 812)" style="fill:#ff9527" d="M0 0h3v4H0z"/>
        <path data-name="Path 9" d="M930.623 215.356v40.685" transform="translate(255 635)" style="stroke:#ff9527;stroke-width:3px;fill:none"/>
        <path data-name="Path 10" d="M905.61 227.209h24.317" transform="translate(255 635)" style="stroke:#ff9527;stroke-width:3px;fill:none"/>
        <path data-name="Path 11" d="M905.61 227.209h24.317" transform="translate(255 649)" style="stroke:#ff9527;stroke-width:3px;fill:none"/>
        <rect data-name="Rectangle 86" width="8" height="4" rx="1" transform="translate(1169 853)" style="fill:#ff9527"/>
        <rect data-name="Rectangle 87" width="8" height="4" rx="1" transform="translate(1169 867)" style="fill:#ff9527"/>
        <rect data-name="Rectangle 88" width="8" height="4" rx="1" transform="translate(1169 881)" style="fill:#ff9527"/>
        <path data-name="Path 12" d="M985.347 212.755v-10.6h8.115v10.6" transform="translate(255 635)" style="stroke:#ff9527;stroke-width:3px;fill:none"/>
        <path data-name="Path 13" d="M999.973 212.755v-23.544l-12.1-7.734-6.552 5.977-2.733-2.651-5.469 4.408 7.737 7.651 4.191-6.065-3.726-3.344" transform="translate(255 635)" style="stroke-linecap:round;stroke-linejoin:round;stroke:#ff9527;stroke-width:3px;fill:none"/>
        <path data-name="Path 14" d="M957.777 227.816V202.7H978.8l-.024 25.116" transform="translate(255 635)" style="fill:#fff;stroke:#ff9527;stroke-width:3px"/>
        <g data-name="Rectangle 89" style="stroke-linecap:round;stroke-linejoin:round;stroke:#ff9527;stroke-width:3px;fill:none">
            <path d="M0 0h33a10 10 0 0 1-10 10H10A10 10 0 0 1 0 0z" style="stroke:none" transform="translate(1207 862)"/>
            <path d="M1.5 1.5h30v0a7 7 0 0 1-7 7h-16a7 7 0 0 1-7-7v0h0z" style="fill:none" transform="translate(1207 862)"/>
        </g>
        <path data-name="Rectangle 90" transform="translate(1222 872)" style="fill:#ff9527" d="M0 0h3v16H0z"/>
        <path data-name="Rectangle 91" transform="translate(1210 881)" style="fill:#ff9527" d="M0 0h28v3H0z"/>
        <path data-name="Rectangle 92" transform="translate(1210 881)" style="fill:#ff9527" d="M0 0h3v7H0z"/>
        <path data-name="Rectangle 93" transform="translate(1234 881)" style="fill:#ff9527" d="M0 0h3v7H0z"/>
        <path data-name="Rectangle 94" transform="translate(1208 888)" style="fill:#ff9527" d="M0 0h7v3H0z"/>
        <path data-name="Rectangle 95" transform="translate(1220 888)" style="fill:#ff9527" d="M0 0h7v3H0z"/>
        <path data-name="Rectangle 96" transform="translate(1232 888)" style="fill:#ff9527" d="M0 0h7v3H0z"/>
    </g>
</svg>
                    <p>My work from home set up</p>
                    <div class="custom_radio_main_box">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                        <label class="form-check-label" for="flexRadioDefault2">
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="word-section-radio">
                    <svg xmlns="http://www.w3.org/2000/svg" width="104.077" height="103.678"
                      viewBox="0 0 104.077 103.678">
                      <g data-name="Group 12330">
                        <path data-name="Path 1" d="m334.695 172.387 66.736-17.968v72.034h-8.013v22.175h-58.723z"
                          transform="translate(-333.195 -152.919)"
                          style="stroke-linecap:round;stroke-linejoin:round;stroke:#ff9527;stroke-width:3px;fill:none" />
                        <g data-name="Group 12326">
                          <g data-name="Rectangle 77" style="stroke:#ff9527;stroke-width:3px;fill:none">
                            <path style="stroke:none" d="M0 0h24v17H0z" transform="translate(8.805 24.081)" />
                            <path style="fill:none" d="M1.5 1.5h21v14h-21z" transform="translate(8.805 24.081)" />
                          </g>
                          <path data-name="Line 1" transform="translate(21.305 26.581)"
                            style="stroke:#ff9527;stroke-width:3px;fill:none" d="M0 0v13" />
                        </g>
                        <g data-name="Group 12327">
                          <g data-name="Rectangle 77" style="stroke:#ff9527;stroke-width:3px;fill:none">
                            <path style="stroke:none" d="M0 0h24v17H0z" transform="translate(36.805 24.081)" />
                            <path style="fill:none" d="M1.5 1.5h21v14h-21z" transform="translate(36.805 24.081)" />
                          </g>
                          <path data-name="Line 1" transform="translate(49.305 26.581)"
                            style="stroke:#ff9527;stroke-width:3px;fill:none" d="M0 0v13" />
                        </g>
                        <g data-name="Group 12328">
                          <g data-name="Rectangle 77" style="stroke:#ff9527;stroke-width:3px;fill:none">
                            <path style="stroke:none" d="M0 0h24v17H0z" transform="translate(8.805 48.081)" />
                            <path style="fill:none" d="M1.5 1.5h21v14h-21z" transform="translate(8.805 48.081)" />
                          </g>
                          <path data-name="Line 1" transform="translate(21.305 50.581)"
                            style="stroke:#ff9527;stroke-width:3px;fill:none" d="M0 0v13" />
                        </g>
                        <g data-name="Group 12329">
                          <g data-name="Rectangle 77" style="stroke:#ff9527;stroke-width:3px;fill:none">
                            <path style="stroke:none" d="M0 0h24v17H0z" transform="translate(36.805 48.081)" />
                            <path style="fill:none" d="M1.5 1.5h21v14h-21z" transform="translate(36.805 48.081)" />
                          </g>
                          <path data-name="Line 1" transform="translate(49.305 50.581)"
                            style="stroke:#ff9527;stroke-width:3px;fill:none" d="M0 0v13" />
                        </g>
                        <path data-name="Path 2" d="M361.068 247.959v-21.71h14v21.71"
                          transform="translate(-333.195 -152.249)" style="stroke:#ff9527;stroke-width:3px;fill:none" />
                        <path data-name="Path 3" d="M400.318 226.918h34.7V255.1h-41.767v-7.2"
                          transform="translate(-332.446 -152.919)"
                          style="stroke-linecap:round;stroke-linejoin:round;stroke:#ff9527;stroke-width:3px;fill:none" />
                        <path data-name="Line 2" transform="translate(61.305 88.581)"
                          style="stroke:#ff9527;stroke-width:3px;fill:none" d="M0 0h41" />
                        <g data-name="Rectangle 78" transform="translate(76.805 83.081)"
                          style="fill:#fff;stroke:#ff9527;stroke-width:3px">
                          <rect width="10" height="10" rx="1" style="stroke:none" />
                          <rect x="1.5" y="1.5" width="7" height="7" rx=".5" style="fill:none" />
                        </g>
                        <path data-name="Path 4"
                          d="M407.9 225.62s0-6.247 4.3-7.125c1.015-.216 4.011-.689 6.294.539 1.849.987 3.361 3.134 3.431 6.586"
                          transform="translate(-333.195 -152.919)" style="stroke:#ff9527;stroke-width:3px;fill:none" />
                      </g>
                    </svg>
                    <p>My office set up</p>
                    <div class="custom_radio_main_box">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                        <label class="form-check-label" for="flexRadioDefault2">
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="word-section-radio">
                    <svg xmlns="http://www.w3.org/2000/svg" width="104.7" height="104.452" viewBox="0 0 104.7 104.452">
    <g data-name="Group 12335" transform="translate(-1159.11 -788.008)">
        <path data-name="Path 5" d="M936.841 220.14v35.82H905.61v-79.609l50.2-21.843 51.5 21.843v79.609h-8.025v-35.82z" transform="translate(255 635)" style="stroke-linecap:round;stroke-linejoin:round;stroke:#ff9527;stroke-width:3px;fill:none"/>
        <path data-name="Path 6" d="M905.61 213.889h101.8" transform="translate(255 635)" style="stroke:#ff9527;stroke-width:3px;fill:none"/>
        <g data-name="Rectangle 82" style="stroke:#ff9527;stroke-width:3px;fill:none">
            <path style="stroke:none" d="M0 0h30v26H0z" transform="translate(1167 816)"/>
            <path style="fill:none" d="M1.5 1.5h27v23h-27z" transform="translate(1167 816)"/>
        </g>
        <g data-name="Rectangle 83" style="fill:#fff;stroke:#ff9527;stroke-width:3px">
            <path style="stroke:none" d="M0 0h17v15H0z" transform="translate(1205 816)"/>
            <path style="fill:none" d="M1.5 1.5h14v12h-14z" transform="translate(1205 816)"/>
        </g>
        <path data-name="Path 7" d="M922.433 207v6.154" transform="translate(255 635)" style="stroke:#ff9527;stroke-width:3px;fill:none"/>
        <path data-name="Path 8" d="M922.433 207v6.154" transform="translate(264 635)" style="stroke:#ff9527;stroke-width:3px;fill:none"/>
        <circle data-name="Ellipse 2" cx="2.5" cy="2.5" r="2.5" transform="translate(1180 832)" style="fill:#ff9527"/>
        <path data-name="Rectangle 84" transform="translate(1208 812)" style="fill:#ff9527" d="M0 0h3v4H0z"/>
        <path data-name="Rectangle 85" transform="translate(1215 812)" style="fill:#ff9527" d="M0 0h3v4H0z"/>
        <path data-name="Path 9" d="M930.623 215.356v40.685" transform="translate(255 635)" style="stroke:#ff9527;stroke-width:3px;fill:none"/>
        <path data-name="Path 10" d="M905.61 227.209h24.317" transform="translate(255 635)" style="stroke:#ff9527;stroke-width:3px;fill:none"/>
        <path data-name="Path 11" d="M905.61 227.209h24.317" transform="translate(255 649)" style="stroke:#ff9527;stroke-width:3px;fill:none"/>
        <rect data-name="Rectangle 86" width="8" height="4" rx="1" transform="translate(1169 853)" style="fill:#ff9527"/>
        <rect data-name="Rectangle 87" width="8" height="4" rx="1" transform="translate(1169 867)" style="fill:#ff9527"/>
        <rect data-name="Rectangle 88" width="8" height="4" rx="1" transform="translate(1169 881)" style="fill:#ff9527"/>
        <path data-name="Path 12" d="M985.347 212.755v-10.6h8.115v10.6" transform="translate(255 635)" style="stroke:#ff9527;stroke-width:3px;fill:none"/>
        <path data-name="Path 13" d="M999.973 212.755v-23.544l-12.1-7.734-6.552 5.977-2.733-2.651-5.469 4.408 7.737 7.651 4.191-6.065-3.726-3.344" transform="translate(255 635)" style="stroke-linecap:round;stroke-linejoin:round;stroke:#ff9527;stroke-width:3px;fill:none"/>
        <path data-name="Path 14" d="M957.777 227.816V202.7H978.8l-.024 25.116" transform="translate(255 635)" style="fill:#fff;stroke:#ff9527;stroke-width:3px"/>
        <g data-name="Rectangle 89" style="stroke-linecap:round;stroke-linejoin:round;stroke:#ff9527;stroke-width:3px;fill:none">
            <path d="M0 0h33a10 10 0 0 1-10 10H10A10 10 0 0 1 0 0z" style="stroke:none" transform="translate(1207 862)"/>
            <path d="M1.5 1.5h30v0a7 7 0 0 1-7 7h-16a7 7 0 0 1-7-7v0h0z" style="fill:none" transform="translate(1207 862)"/>
        </g>
        <path data-name="Rectangle 90" transform="translate(1222 872)" style="fill:#ff9527" d="M0 0h3v16H0z"/>
        <path data-name="Rectangle 91" transform="translate(1210 881)" style="fill:#ff9527" d="M0 0h28v3H0z"/>
        <path data-name="Rectangle 92" transform="translate(1210 881)" style="fill:#ff9527" d="M0 0h3v7H0z"/>
        <path data-name="Rectangle 93" transform="translate(1234 881)" style="fill:#ff9527" d="M0 0h3v7H0z"/>
        <path data-name="Rectangle 94" transform="translate(1208 888)" style="fill:#ff9527" d="M0 0h7v3H0z"/>
        <path data-name="Rectangle 95" transform="translate(1220 888)" style="fill:#ff9527" d="M0 0h7v3H0z"/>
        <path data-name="Rectangle 96" transform="translate(1232 888)" style="fill:#ff9527" d="M0 0h7v3H0z"/>
    </g>
</svg>
                    <p>My work from home set up</p>
                    <div class="custom_radio_main_box">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                        <label class="form-check-label" for="flexRadioDefault2">
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                
              </carousel> -->
            </div>
          </div>

          <div class="col-md-12">
            <div class="self_assess_main_box">
              <h2></h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- self-assessment end  -->
    <section class="already_completed text-center my_54" v-if="company_data.schedule_request_status">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-8">
            <div>
              <h3 class="text_black">Already completed a self-assessment?</h3>
              <p class="text_balticsea">Already completed your self-assessment, but still need a bit more help? We’ve got you covered. Schedule a one-on-one ergo evaluation with one of our leading ergonomists.</p>
              <router-link :to="{ name:'schedule_eval' }" class="btn btn_primary">Schedule an Evaluation</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
  // import carousel from "vue-owl-carousel"
  export default {
    name: 'self_assessment',
    data() {
         return {
            // company_data : null,
            company_data : this.$store.getters["auth/authUser"].client ?? null,
            user_data:[],
            lastName:"",
         };
      },
    components: {
      // carousel
    },
    mounted(){
        // this.company_data = JSON.parse(localStorage.getItem("companyInformation"));  
        let data = this.$store.getters["auth/authUser"];
        this.company_data = data.client;
        this.user_data = data.user;
        this.lastName = this.user_data.last_name ? this.user_data.last_name : "";
      }
  }
</script>
